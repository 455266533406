#video {
  width: 100vw;
  height: 75vh;
  object-fit: cover;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: -1;
}

.landing {
  display: grid;
  margin-right: 2vw;
  margin-left: 2vw;
  grid-column-gap: 1vh;
  grid-template-columns: repeat(12, 1fr);
}

.landing img {
  grid-column: 1 / 2;
  margin: 20vh -30% -50% 50%;
}

.landing h1 {
  font-weight: 700;
  font-size: 5rem;
  color: white;
  margin: 0;
  margin-top: 25vh;
  grid-column: 2 / 8;
  width: 100%;
}

.landing p {
  color: white;
  grid-column: 2 / 8;
}
.header-orange {
  color: var(--orange);
}

@media screen and (min-width: 700px) and (max-width: 1024px) {
  /* Small Screen View */
  #video {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: -1;
  }

  .landing {
    margin-right: 10vw;
    margin-left: 10vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-height: 100vh;
  }

  .landing img {
    display: none;
  }

  .landing p {
    color: white;
    max-width: 50%;
    margin-top: 50px;
    font-size: 0.75rem;
  }

  .landing h1 {
    font-weight: 700;
    font-size: 6rem;
    color: white;
    margin: -10vh 0 0 0;
  }

  .header-orange {
    font-weight: 700;
    font-size: 6rem;
    color: var(--orange);
  }
}

@media screen and (max-width: 699px) {
  /* Mobile View */

  #video {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: -1;
  }

  .landing {
    margin-right: 2vw;
    margin-left: 2vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
  }

  .landing img {
    display: none;
  }

  .landing p {
    color: white;
    text-align: center;
    max-width: 50%;
    margin-top: 50px;
    font-size: 0.75rem;
  }

  .landing h1 {
    font-weight: 700;
    font-size: 4rem;
    color: white;
    margin: 0 0 0 0;
    text-align: center;
  }

  .header-orange {
    font-weight: 700;
    font-size: 4rem;
    color: var(--orange);
  }
}
