.vision-section {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 1vw;
  margin-top: 100px;
}

.vision-section-header {
  grid-column: 6 / span 2;
  font-size: 2.5em;
}

.vision-section-header p {
  margin: -30px 0 0 40px;
}

.vision-section-content {
  grid-column: 3 / span 8;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-column-gap: 1vw;
}

.vision-section-content img {
  grid-column: span 3;
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;
}

.vision-section-content p {
  grid-column: span 5;
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
  font-weight: 300;
  line-height: 153%;
}

@media screen and (min-width: 700px) and (max-width: 1024px) {
  .vision-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }

  .vision-section-header p {
    font-size: 2.5rem;
    text-align: center;
  }

  .vision-section-content {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    width: 50vw;
    margin-top: 30px;
  }

  .vision-section-content img {
    margin-top: 30px;
    width: 40vw;
  }
}

@media screen and (max-width: 699px) {
  .vision-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }

  .vision-section-header p {
    font-size: 2rem;
    text-align: center;
    margin: 0;
  }

  .vision-section-header img {
    display: none;
  }

  .vision-section-content {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    width: 70vw;
    margin-top: 30px;
  }

  .vision-section-content p {
    font-size: 12px;
  }

  .vision-section-content img {
    margin-top: 30px;
    width: 50vw;
  }
}
