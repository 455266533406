.Profile_Container{
	position: relative;
    width: 21.39vw;
    height: auto;
    margin-left: 2.5vw;
    margin-top: 2vw;
	background: #F6F6F5;
	border-radius: 3.33vw;
	overflow:auto;
	padding-top:1.5vw;
	padding-bottom:1.5vw;
}

.Profile_Pic{
	position: relative;
	width: 7.6vw;
	height: 7.6vw;
	justify-content:center;
	border: 0.2vw solid #F3752B;
	box-sizing: border-box;
	border-radius: 3.8vw;
	object-fit:contain;
}

.Profile_Name{
	position: relative;
    margin-right:2vw;
	margin-left:2vw;
	margin-top:1.25vw;
	font-family: SophiaPro;
	font-style: normal;
	font-weight: 600;
	font-size: 1.25vw;
	line-height: 1.5vw;
	align-items: center;
	text-align: center;
	color: #000000;
}

.Profile_Role{
	position: relative;
	font-family: SophiaPro;
	font-style: normal;
	font-weight: normal;
	font-size: 1.25vw;
	line-height: 1.5vw;
	margin-top:0.9vw;
	text-align:center;
	align-items: center;
	color: #595959;
}

.Profile_UserCode{
	position: relative;
	font-family: SophiaPro;
	font-style: normal;
	font-weight: normal;
	font-size: 1.25vw;
	line-height: 1.5vw;
	margin-top:0.9vw;
	text-align:center;
	align-items: center;
	color: #595959;
}

.Profile_Docs{
	position: relative;
    width: 21.34vw;
	max-height:27vw;
    margin-left: 2.5vw;
    margin-top: 1.5vw;
	margin-bottom: 1.5vw;
	background: #F6F6F5;
	border-radius: 2.77vw;
	padding-bottom:1.18vw;
	padding-top:1.52vw;
	overflow:auto;
}

.Profile_Docs_Title{
	font-family: SophiaPro;
	font-style: normal;
	font-weight: normal;
	font-size: 1.25vw;
	line-height: 1.5vw;
	text-align:center;
	align-items: center;
	color: #646464;
	/* margin-top:1.80vw; */
}

.Profile_Doc{
	
}

.Profile_id1{
	position: relative;
    width: 17.375vw;
	min-height: 6.77vw;
	border-radius: 1.66vw;
	border: 0.2vw solid #F3752B;
	margin-top:1.5vw;
}

.Profile_Doc_Label{
	position: relative;
	width: fit-content;
	margin-top: 0.11vw;
	background: #F3752B;
	border-radius: 0.97vw;
	padding-bottom:0.73vw;
	padding-top:0.73vw;
	padding-left:1.16vw;
	padding-right:1.16vw;
	text-align:center;
	font-family: SophiaPro;
	font-style: normal;
	font-weight: normal;
	font-size: 0.97vw;
	line-height: 1.13vw;
	align-items: center;
	color: #FFFFFF;
}

.Profile_PersonalInfo{
	position: absolute;
	top: 2vw;
	width: 45.27vw;
	height: 46vw;
	margin-left: 26.9vw;
	background: #F6F6F5;
	border-radius: 3.33vw;
	padding-left:3.26vw;
	padding-right:3.26vw;
	padding-bottom: 2vw;
	overflow-y:auto;
}

.Profile_PersonalInfo_Title{
	position: relative;
	margin-left: 4.72vw;
	margin-top: 2.4vw;
	margin-button: 2.4vw;
	font-family: SophiaPro;
	font-style: normal;
	font-weight: normal;
	font-size: 1.944vw;
	line-height: 2vw;
	display:flex;
	align-items: center;
	color: #646464;
}

.Profile_Edit_Button{
	position: absolute;
	width: fit-content;
	height: 2.67vw;
	margin-left:35.56vw;
	padding-bottom:0.96vw;
	padding-left:0.59vw;
	padding-right:0.59vw;	
	background: #F3752B;
    border-radius: 0.83vw;
	font-family: SophiaPro;
	font-style: normal;
	font-weight: normal;
	font-size: 1.11vw;
	line-height: 1.3vw;
	display: flex;
	align-items: center;
	color: #FFFFFF;
	cursor:pointer;
}

.Profile_Edit_Button_Disabled{
	position: absolute;
	width: fit-content;
	height: 2.67vw;
	margin-left:35.56vw;
	padding-bottom:0.96vw;
	padding-left:0.59vw;
	padding-right:0.59vw;	
    border-radius: 0.83vw;
	font-family: SophiaPro;
	font-style: normal;
	font-weight: normal;
	font-size: 1.11vw;
	line-height: 1.3vw;
	display: flex;
	align-items: center;
	color: #FFFFFF;
	background: #7E8080;
}

.Profile_Edit_Icon{
	font-size: 1.11vw;
	padding-top:0.96vw;
}

.Profile_Field_Container{
	display:flex;
	flex-direction:column;
	
}

.Profile_Fields{
	display:flex;
	flex-direction:row;
	position:relative;
	margin-top:2.375vw;
	width:38.75vw;
	height:fit-content;
}

.Profile_field{
	display:flex;
	flex-direction:column;
	width:fit-content;
	height:fit-content;
	margin-right:1.7vw;
}

.Profile_field_label{
	font-family: SophiaPro;
	font-style: normal;
	font-weight: normal;
	font-size: 1.11vw;
	line-height: 1.3vw;
	align-items: center;
	color: #7E8080;
	margin-bottom:0.36vw;
	padding-left:0.76vw;
}

.Profile_field_input_enabled{
	position: relative;
	background: #FFFFFF;
	border-radius: 0.74vw;
	font-family: SophiaPro;
	font-style: normal;
	font-weight: normal;
	font-size: 1.11vw;
	line-height: 1.3vw;
	color: #000000;
	padding:0.76vw;
	-webkit-box-shadow:0 0.5vw 0.5vw rgba(0, 0, 0, 0.5);   
    -moz-box-shadow:0 0.5vw 0.5vw rgba(0, 0, 0, 0.5);
    box-shadow:0 0.5vw 0.5vw rgba(0, 0, 0, 0.5);
}

.Profile_field_input{
	position: relative;
	background: #FFFFFF;
	border-radius: 0.74vw;
	font-family: SophiaPro;
	font-style: normal;
	font-weight: normal;
	font-size: 1.11vw;
	line-height: 1.3vw;
	align-items: center;
	color: #000000;
	padding:0.76vw;
}

.Profile_Save_Button{
	position: absolute;
	width: fit-content;
	height: 2.67vw;
	margin-left:34.56vw;
	padding-bottom:0.96vw;
	padding-left:0.59vw;
	padding-right:0.59vw;	
	background: #F3752B;
    border-radius: 0.83vw;
	font-family: SophiaPro;
	font-style: normal;
	font-weight: normal;
	font-size: 1.11vw;
	line-height: 1.3vw;
	display: flex;
	align-items: center;
	color: #FFFFFF;
	margin-top:33.6vw;
	cursor:pointer;
}

.Profile_Save_Button_Disabled{
	position: absolute;
	width: fit-content;
	height: 2.67vw;
	margin-left:34.56vw;
	padding-bottom:0.96vw;
	padding-left:0.59vw;
	padding-right:0.59vw;	
	background: #7E8080;
    border-radius: 0.83vw;
	font-family: SophiaPro;
	font-style: normal;
	font-weight: normal;
	font-size: 1.11vw;
	line-height: 1.3vw;
	display: flex;
	align-items: center;
	color: #FFFFFF;
	margin-top:33.6vw;
}

.Profile_Save_Icon{
	font-size: 1.11vw;
	padding-top:0.96vw;
}