.team-section {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 1vw;
  margin-top: 100px;
}

.team-header {
  grid-column: 6 / span 2;
  font-size: 2.5em;
  text-align: center;
}

.team-header p {
  margin: 0;
  margin-top: -30px;
}

.team-header img {
  margin-left: -135px;
}

.team-cards {
  grid-column: 2 / span 10;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 50px;
}

.team-card {
  max-width: 30vw;
  min-width: 400px;
  max-height: 40vw;
  min-height: 420px;
  box-shadow: -4px -4px 50px rgba(0, 0, 0, 0.04),
    4px 4px 50px rgba(0, 0, 0, 0.04);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  padding: 25px;
  margin-bottom: 50px;
  align-items: center;
}

.team-card img {
  min-width: 150px;
  min-height: 150px;
  width: 10.5vw;
  height: 10.5vw;
  object-fit: cover;
  border-radius: 50%;
}

.card-top {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}

.card-top p {
  text-align: center;
}

.designation .name {
  margin: 0;
  margin-bottom: 5px;
}

.designation .position {
  font-weight: 300;
  font-size: 0.75em;
  margin: 0;
}

.content {
  text-align: center;
  padding-left: 25px;
  padding-right: 25px;
  font-weight: 300;
  width: 90%;
  margin-top: 30px;
  font-size: 0.9em;
  line-height: 1.5em;
  letter-spacing: 0.02em;
}

.border-orange {
  border: 4px solid var(--orange);
}

.border-blue {
  border: 4px solid var(--blue);
}

.text-orange {
  color: var(--orange);
}

.text-blue {
  color: var(--blue);
}

@media screen and (min-width: 700px) and (max-width: 1024px) {
  .team-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .team-header p {
    font-size: 2.5rem;
    text-align: center;
    margin: 0;
    margin-top: -30px;
  }

  .team-cards {
    flex-direction: column;
  }
}

@media screen and (max-width: 699px) {
  .team-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .team-header p {
    font-size: 2.5rem;
    text-align: center;
    margin: 0;
    margin-top: -30px;
  }

  .team-cards {
    flex-direction: column;
  }

  .team-card {
    min-width: none;
    min-height: none;
    max-width: 350px;
    max-height: 400px;
    height: auto;
    width: auto;
  }

  .team-card img {
    min-width: 100px;
    min-height: 100px;
    width: 10.5vw;
    height: 10.5vw;
    object-fit: cover;
    border-radius: 50%;
  }

  .designation p {
    font-size: 16px;
  }
  .designation .position {
    font-size: 12px;
  }

  .content {
    font-size: 12px;
  }
}
