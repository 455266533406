.Icon {
  border-radius: 50%;
  background: var(--blue);
  width: 145px;
  height: 145px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex {
  grid-column: 2 / 12;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  margin-right: 2vw;
  margin-left: 2vw;
  grid-column-gap: 1vh;
  margin-top: 100px;
  margin-bottom: 100px;
}

.caption {
  font-weight: 500;
}

@media screen and (max-width: 1024px) {
  .flex {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .flex div {
    margin-bottom: 20px;
  }
  .caption {
    text-align: center;
  }
  .caption p {
    font-size: 0.75rem;
  }
}
