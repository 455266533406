*,
*:before,
*:after,
ul,
li,
a,
button,
/* input, */
h1,
h2,
h3,
h4,
h5,
h6,
p,
img,
image,
svg,
path,
g,
canvas {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: transparent;
  border: none;
  text-decoration: none;
  /* font-family: "Roboto"; */
  user-select: none;
  list-style: none;
  position: relative;
}

.html,
body {
  background-color: #fafafa;
  margin: 0;
  padding: 0;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 1px 2px rgba(0, 0, 0, 0.12);
  background-color: #f2f8ff;
  text-transform: capitalize;
  cursor: pointer;
}
.button:hover,
.button:focus {
  transition: 0.15s ease-in-out;
}

.login-container {
  width: 40vw;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 20vh auto;
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.06), 0 1px 2px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  padding-top: 10px;
  padding-bottom: 10px;
}
.login-container .logo {
  width: 7vw;
  height: 7vw;
  margin-top: 3vw;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.5vw;
  /* background: url("login_logo.jpg")
    no-repeat; */
	/* background-image: url("login_logo.jpg"); */
  background-size: contain;
	background-repeat: no-repeat;
}
.login-container .title {
  text-transform: capitalize;
  font-family: Nasalization;
  font-weight: 400;
  font-size: 2vw;
  font-weight: 300;
  padding-bottom: 10px;
  color: #7b7b7b;
}
.login-container .login-button {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 60%;
  padding: 20px 15px;
  background-color: #007aff;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 400;
  margin: 40px 0;
}
.login-container .login-button:hover,
.login-container .login-button:focus {
  box-shadow: 0px 3px 20px 3px rgba(0, 122, 255, 0.3);
  background-color: #007aff;
}

.login-container .input {
	width: 23vw ;
	height: 4vw;
	margin: 0.5vw auto;
	padding-left: 0.25vw;
	padding-right: 0.25vw;
	background: #E8F6F9;
	border-radius: 0.75vw;
	font-size: 1.5vw;
	line-height: 1.7vw;
	border-width:thin;
	border-color:#00B4D8;
	font-family: SophiaPro;
font-style: normal;
font-weight: normal;
	text-align: center;
	color: #00B4D8;	
}

.login-container .OTPinput {
	width: 23vw ;
	height: 4vw;
	margin: 0.5vw auto;
	padding-left: 0.25vw;
	padding-right: 0.25vw;
	background: #E8F6F9;
	border-radius: 0.75vw;
	font-size: 1.5vw;
	line-height: 1.7vw;
	border-width:thin;
	border-color:#00B4D8;
	font-family: SophiaPro;
font-style: normal;
font-weight: normal;
	text-align: center;
	color: #00B4D8;	
}

.login-container .OTPinput::placeholder {
  font-size: 1.5vw;
  line-height: 1.7vw;
  text-align: center;
  color: #00b4d8;
  font-family: SophiaPro;
  font-style: normal;
  font-weight: normal;
}

.login-container .input::placeholder {
  font-size: 1.5vw;
  line-height: 1.7vw;
  text-align: center;
  color: #00b4d8;
  font-family: SophiaPro;
  font-style: normal;
  font-weight: normal;
}

.icon::placeholder {
  /* background: url("https://raw.githubusercontent.com/Nikitha2309/Private/main/User.png?token=APXZ46LFDU5FFC3PLTWL2UTBPGKRY")
    no-repeat; */
  background-size: contain;
  align: center;
}

.button-custom {
  display: flex;
  flex-direction: row;
  width: auto;
  height: 2vw;
  margin-bottom: 2vw;
  align-items: center;
  margin-top: 2vw;
}
.button-title {
  font-weight: bold;
  font-size: 1.7vw;
  line-height: 1.8vw;
  color: #7b7b7b;

  font-family: SophiaPro;
  font-style: normal;
  font-weight: bold;
}

.button-arrow {
  margin-left: 0.6vw;
  width: 2vw;
  height: 2vw;
  /* background: url("https://raw.githubusercontent.com/Nikitha2309/Private/main/Next%20button.png?token=APXZ46KMMJ5PAB22ERAUXILBPGL62")
    no-repeat; */
  background-size: contain;
}

.text {
  display: flex;
  flex-direction: row;
  width: auto;
  height: 2vw;
  /* margin-bottom: 0.75vw; */
  align-items: center;
  margin-top: 1vw;
  /* margin-left: */
}

.OTP_timer {
  font-size: 1vw;
  margin-right: 2vw;
  color: #7b7b7b;
  font-family: SophiaPro;
  font-style: normal;
  font-weight: 400;
}

.otp_resend {
  font-size: 1vw;
  color: #7b7b7b;
  font-family: SophiaPro;
  font-style: normal;
  font-weight: 400;
}
