.card {
  background: var(--blue);
  max-height: 20vw;
  max-width: 20vw;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 250px;
  min-width: 200px;
}

.card p {
  width: 74%;
  text-align: center;
  color: var(--white);
}

.service-cards {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 5vw;
  margin-left: 5vw;
  margin-bottom: 100px;
}

@media screen and (min-width: 700px) and (max-width: 1024px) {
  .service-cards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .card {
    max-width: 50vw;
    width: 50vw;
    margin: 10px;
  }

  .card p {
    font-size: 1rem;
  }
}

@media screen and (max-width: 699px) {
  .service-cards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .card {
    max-width: 80vw;
    width: 80vw;
    margin: 10px;
  }

  .card p {
    font-size: 0.75rem;
  }
}
