.services-header {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  margin-right: 2vw;
  margin-left: 2vw;
  grid-column-gap: 1vh;
  margin-top: 75px;
}
.services-main-header {
  grid-column: 6/7;
  font-weight: 500;
  font-size: 2.5em;
  margin: 0;
}

.services-header-img {
  grid-column: 6;
  margin-bottom: -24px;
  margin-left: -34px;
}

.full-load {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  margin-right: 2vw;
  margin-left: 2vw;
  grid-column-gap: 1vh;
  margin-top: 50px;
}
.last-mile {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  margin-right: 2vw;
  margin-left: 2vw;
  margin-top: 4vw;
  grid-column-gap: 1vh;
}

.service-left {
  grid-column: 3 / 7;
}

.service-left a {
  color: var(--black);
  text-decoration: none;
}

.service-left a:visited {
  color: var(--black);
  text-decoration: none;
}

.service-right a {
  color: var(--black);
  text-decoration: none;
}

.service-right a:visited {
  color: var(--black);
  text-decoration: none;
}

.service-right {
  grid-column: 7 / 11;
  width: 100%;
}

.color-orange {
  color: var(--orange);
  font-size: 2.5rem;
  font-weight: 500;
}

.loading-header {
  margin: -60px 0 0 60px;
  font-size: 2.5rem;
  font-weight: 500;
}

.arrowRight {
  margin-left: 15px;
  margin-top: 10px;
}

.service-left .service-content {
  margin-left: 60px;
}

.last-mile .service-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.service-right .loading-header {
  margin: -45px 60px 0 0;
  font-size: 2.5rem;
  font-weight: 500;
  text-align: right;
}

.arrowLeft {
  margin-right: 10px;
}

.service-right .service-content {
  margin-right: 60px;
  margin-left: 0;
  text-align: right;
}

.service-right .loading {
  float: right;
}

@media screen and (min-width: 700px) and (max-width: 1024px) {
  /* Small Screen View */
  .services-header {
    width: 100%;
    height: auto;
    display: block;
    margin-right: 2vw;
    margin-left: 2vw;
    margin-top: 75px;
    text-align: center;
  }
  .services-main-header {
    font-weight: 500;
    font-size: 2.5em;
    margin: 0;
  }

  .services-header-img {
    margin-left: -130px;
  }

  .full-load {
    width: 100%;
    display: flex;
    margin-right: 2vw;
    margin-left: 2vw;
    flex-direction: column;
    margin-top: 50px;
    justify-content: center;
    align-items: center;
  }

  .full-load .service-left {
    width: auto;
  }
  .loading-header a {
    font-size: 2.5rem;
  }

  .service-content {
    font-size: 1rem;
  }

  .full-load .service-right {
    width: fit-content;
    margin-top: 50px;
  }

  .service-right .loading-header a {
    font-size: 2.5rem;
  }

  .last-mile {
    width: 100%;
    display: flex;
    margin-right: 2vw;
    margin-left: 2vw;
    flex-direction: column-reverse;
    margin-top: 50px;
    justify-content: center;
    align-items: center;
  }

  .last-mile .service-right {
    width: auto;
  }

  .last-mile .service-left {
    width: fit-content;

    margin-top: 50px;
  }
}

@media screen and (max-width: 699px) {
  .services-header {
    width: 100%;
    height: auto;
    display: block;
    margin-right: 2vw;
    margin-left: 2vw;
    margin-top: 75px;
    text-align: center;
  }
  .services-main-header {
    font-weight: 500;
    font-size: 3rem;
    margin: 0;
  }
  .services-header-img {
    display: none;
  }
  .full-load {
    width: 100%;
    display: flex;
    margin-right: 2vw;
    margin-left: 2vw;
    flex-direction: column;
    margin-top: 50px;
    justify-content: center;
    align-items: center;
  }

  .full-load .service-left {
    width: auto;
    text-align: center;
  }
  .service-left img {
    display: none;
  }
  /* .service-left .arrowRight {
    display: block;
  } */

  .full-load .service-right {
    width: fit-content;
    margin-top: 50px;
  }
  .loading-header {
    margin: 0;
  }

  .loading-header span {
    margin: 0;
  }

  .service-content {
    margin: 0;
    font-size: 1rem;
  }

  .full-load > div:nth-child(1) > p:nth-child(3) {
    margin-left: 0px;
  }
  .last-mile {
    width: 100%;
    display: flex;
    margin-right: 2vw;
    margin-left: 2vw;
    flex-direction: column-reverse;
    margin-top: 50px;
    justify-content: center;
    align-items: center;
  }

  .last-mile .service-right {
    width: auto;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .last-mile .service-left {
    width: fit-content;
    margin-top: 50px;
  }
  .last-mile > div:nth-child(2) > p:nth-child(3) {
    margin-right: 0px;
  }
  .last-mile > div:nth-child(2) > p:nth-child(2) {
    margin-right: 0px;
  }

  .loading-header a {
    font-size: 2.5rem;
  }
  .service-right img {
    display: none;
  }

  .service-right .service-content {
    text-align: center;
  }
}
