.service-page-content {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  margin-right: 2vw;
  margin-left: 2vw;
  grid-column-gap: 1vh;
  margin-top: 100px;
  margin-bottom: 100px;
}

.lastmile-content {
  grid-column: 3 / span 4;
  text-align: left;
}

.lastmile-illustration {
  grid-column: 7 / span 4;
  display: flex;
  justify-content: right;
}

.lastmile-heading {
  font-size: 3em;
  font-weight: 500;
  margin: -55px 0 0 60px;
}

.lastmile-heading span {
  color: var(--orange);
}

.lastmile-content p {
  margin-left: 60px;
}

@media screen and (min-width: 700px) and (max-width: 1024px) {
  .service-page-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .lastmile-content {
    text-align: center;
  }

  .lastmile-content img {
    display: none;
  }

  .lastmile-heading {
    margin: 0;
    font-size: 3rem;
    font-weight: 500;
  }

  .lastmile-heading span {
    font-size: 3rem;
    font-weight: 500;
  }

  .lastmile-content p {
    margin: 0 0 30px 0;
  }
}

@media screen and (max-width: 699px) {
  .service-page-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .lastmile-content {
    text-align: center;
  }

  .lastmile-content img {
    display: none;
  }

  .lastmile-content .lastmile-heading {
    margin: 0;
    font-size: 3rem;
    font-weight: 500;
  }

  .lastmile-heading span {
    font-size: 3rem;
    font-weight: 500;
  }

  .lastmile-content p {
    margin: 30px 0 30px 0;
    font-size: 0.75rem;
  }
}
