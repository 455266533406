.client-section {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 1vw;
  margin-top: 100px;
}

.client-header {
  grid-column: 5 / span 4;
  font-size: 2em;
}

.client-header p {
  margin: -30px 0 0 0;
  text-align: center;
}
.client-header > img:nth-child(1) {
  margin-left: 190px;
}

.client-content {
  grid-column: 3 / span 8;
  text-align: center;
  font-weight: 300;
  line-height: 153%;
  margin: 50px auto 50px auto;
}

@media screen and (min-width: 700px) and (max-width: 1024px) {
  .client-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }

  .client-header p {
    font-size: 2rem;
    text-align: center;
    margin-top: -30px;
  }
  .client-header > img:nth-child(1) {
    margin-left: -35px;
  }
  .client-header > p:nth-child(2) {
    margin-left: 0px;
  }

  .client-content {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    width: 50vw;
    margin-top: 30px;
  }
}

@media screen and (max-width: 699px) {
  .client-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 100px;
  }

  .client-header p {
    font-size: 2rem;
    text-align: center;
    margin-top: -30px;
    margin-left: 0;
  }

  .client-header img {
    display: none;
  }

  .client-content {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    width: 70vw;
    margin-top: 30px;
    font-size: 1.8em;
  }
}
