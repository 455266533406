.fulltruck-page-content {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  margin-right: 2vw;
  margin-left: 2vw;
  grid-column-gap: 1vh;
  margin-top: 100px;
  margin-bottom: 100px;
}
.fulltruck-illustration {
  grid-column: 3 / span 4;
}

.fulltruck-content {
  grid-column: 7 / span 4;
  text-align: right;
}

.fulltruck-heading {
  font-size: 3rem;
  font-weight: 500;
  margin: -55px 65px 0 0;
}

.fulltruck-heading span {
  color: var(--orange);
}

.fulltruck-content p {
  margin-right: 65px;
}

@media screen and (min-width: 700px) and (max-width: 1024px) {
  .fulltruck-page-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .fulltruck-content {
    text-align: center;
  }

  .fulltruck-content img {
    display: none;
  }

  .fulltruck-heading {
    margin: 0;
  }

  .fulltruck-heading span {
    font-size: 3rem;
    font-weight: 500;
  }
}

@media screen and (max-width: 699px) {
  .fulltruck-page-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .fulltruck-content {
    text-align: center;
  }

  .fulltruck-content img {
    display: none;
  }

  .fulltruck-heading {
    font-size: 3rem;
    margin: 0;
    font-weight: 500;
  }

  .fulltruck-content .fulltruck-heading {
    font-size: 3rem;
    font-weight: 500;
  }

  .fulltruck-heading span {
    font-size: 3rem;
    font-weight: 500;
  }

  .fulltruck-content p {
    font-size: 0.75rem;
    margin-right: 0;
  }
}
