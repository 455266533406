ul {
  list-style-type: none;
}

nav {
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: none;
  display: grid;
  grid-column-gap: 1vh;
  grid-template-columns: repeat(12, 1fr);
  z-index: 100;
  border-bottom: 1px solid var(--blue);
}
.solid-nav {
  background-color: var(--white);
}

.nav-logo {
  grid-column: 3 / span 2;
}

.nav-items {
  grid-column: span 5 / 11;
}
.nav-items ul {
  width: 100%;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

/* .NavItem a {
  padding: 0 10px;
  font-weight: 700;
  text-decoration: none;
  color: var(--black);
} */

.NavItem {
  color: var(--black);
}

.NavItem:hover {
  color: var(--orange);
}

#NavItem-Home,
#NavItem-About,
#NavItem-Services,
#NavItem-Partner,
#NavItem-Contact {
  font-family: SophiaPro;
  font-weight: 500;
  letter-spacing: 0.5px;
  /* font-size: 1.2rem; */
  color: var(--black);
}

#NavItem-Home:hover,
#NavItem-About:hover,
#NavItem-Services:hover,
#NavItem-Partner:hover,
#NavItem-Contact:hover {
  color: var(--orange);
}

.NavItem a {
  font-family: SophiaPro;
  color: var(--black);
}

.NavItem a:hover {
  color: var(--orange);
}

li a:visited {
  color: var(--black);
}

li.active a {
  color: var(--blue);
}

li:hover a {
  color: var(--orange);
}

.Service-Dropdown {
  grid-column: 8 / span 2;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
  width: fit-content;
  /* background-color: var(--white); */
  background-color: none;
  font-weight: 700;
  text-decoration: none;
  color: var(--black);
  padding: 10px;
  border-radius: 0 0 14px 14px;
  border-right: 2px solid var(--blue);
  border-left: 2px solid var(--blue);
  border-bottom: 2px solid var(--blue);
}

.extra-class {
  position: fixed;
  width: 100vw;
  top: 47px;
  left: 0;
  background-color: none;
  display: grid;
  grid-column-gap: 1vh;
  grid-template-columns: repeat(12, 1fr);
  z-index: 101;
  height: 10vh;
}

.Service-Dropdown a {
  text-decoration: none;
  color: var(--black);
}

.Service-Dropdown a:hover {
  color: var(--orange);
}

.Service-Dropdown a:visited {
  color: var(--black);
}

.Service-Dropdown hr {
  margin: 0;
}

#bg-none {
  background: none;
}

@media screen and (min-width: 700px) and (max-width: 1024px) {
  #NavItem-Home,
  #NavItem-About,
  #NavItem-Services,
  #NavItem-Partner,
  #NavItem-Contact {
    font-family: SophiaPro;
    font-weight: 500;
    letter-spacing: 0.5px;
    font-size: 16px;
    color: var(--black);
  }

  #NavItem-Home:hover,
  #NavItem-About:hover,
  #NavItem-Services:hover,
  #NavItem-Partner:hover,
  #NavItem-Contact:hover {
    color: var(--orange);
  }

  .NavItem a {
    font-family: SophiaPro;
    color: var(--black);
  }

  .NavItem a:hover {
    color: var(--orange);
  }
}

@media screen and (max-width: 699px) {
  #NavItem-Home,
  #NavItem-About,
  #NavItem-Services,
  #NavItem-Partner,
  #NavItem-Contact {
    font-family: SophiaPro;
    font-weight: 500;
    letter-spacing: 0.5px;
    font-size: 12px;
    color: var(--black);
  }

  #NavItem-Home:hover,
  #NavItem-About:hover,
  #NavItem-Services:hover,
  #NavItem-Partner:hover,
  #NavItem-Contact:hover {
    color: var(--orange);
  }

  .NavItem a {
    font-family: SophiaPro;
    color: var(--black);
  }

  .NavItem a:hover {
    color: var(--orange);
  }
}
