.review-section {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 1vw;
  margin-top: 100px;
}

.review-header {
  grid-column: 5 / span 4;
  font-size: 2em;
}

.review-header p {
  margin: -30px 0 0 0;

  text-align: center;
}
.review-header > img:nth-child(1) {
  margin-left: 130px;
}

.cards {
  grid-column: 1 / span 12;
  margin-top: 50px;
  margin-bottom: 100px;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
}

.review-card {
  width: 42vw;
  min-height: 36vw;
  border-radius: 30px;
  box-shadow: -4px -4px 50px rgba(0, 0, 0, 0.04),
    4px 4px 50px rgba(0, 0, 0, 0.04);
  margin-right: 50px;
}
.review-card img {
  min-width: 150px;
  min-height: 150px;
  width: 10.5vw;
  height: 10.5vw;
  object-fit: cover;
  border-radius: 50%;
  margin-left: 50%;
  transform: translateX(-50%);
  margin-top: 43px;
  margin-bottom: 20px;
}
.customer-img-blue {
  border: 4px solid var(--blue);
}
.customer-img-orange {
  border: 4px solid var(--orange);
}
.review-card p {
  width: 100%;
  text-align: center;
  font-weight: 300;
  line-height: 153%;
}

.customer-review {
  margin-top: 43px;
  max-width: 70%;
  margin-right: auto;
  margin-left: auto;
}

.customer-name {
  margin-bottom: 0;
}
.customer-company {
  font-weight: 300;
  font-size: 14px;
  line-height: 129%;
  margin-top: 0;
  margin-bottom: 0;
}

.text-orange {
  color: var(--orange);
}

.text-blue {
  color: var(--blue);
}

@media screen and (min-width: 700px) and (max-width: 1024px) {
  div.review-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .review-header {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .review-section .review-header p {
    font-size: 2rem;
    text-align: center;
    margin: 40px 0 0 -15px;
  }

  .review-section .review-header img {
    /* display: none; */
    margin: 0;
  }
}

@media screen and (max-width: 699px) {
  div .review-section {
    display: flex;
    flex-direction: column;
  }
  .review-header {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .review-section .review-header p {
    font-size: 2rem;
  }
  .review-section .review-header img {
    display: none;
  }

  .review-card img {
    min-width: 100px;
    min-height: 100px;
    width: 10.5vw;
    height: 20vw;
    object-fit: cover;
    border-radius: 50%;
    margin-left: 50%;
    transform: translateX(-50%);
    margin-top: 43px;
  }

  .review-card {
    margin-right: 20px;
  }

  .customer-review {
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 80%;
    margin-right: auto;
    margin-left: auto;
  }
}
