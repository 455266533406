.header {
  width: 70vw;
  display: flex;
  flex-direction: row;
  margin: 50px auto;
}
.header h1 span {
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 2.5rem;
  color: var(--black);
}

.header h1 {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.5rem;
  color: var(--orange);
}

.table-service-request-header {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  background-color: #e9e9e9;
  border-radius: 22px 22px 0 0;
}

.table-service-request-row {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

.table-service-request {
  background: #f6f6f5;
  max-height: 56vh;
  justify-content: space-between;
  width: 69vw;
  margin: 25px auto;
  border-radius: 22px;
}

.request-rows {
  max-height: 49vh;
  overflow-y: scroll;
  border-radius: 0 0 22px 22px;
}

.table-service-request ul {
  height: 55px;
}
.table-service-request-header li {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 10vw;
}

.table-service-request-row li {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10vw;
  overflow-x: hidden;
}

.table-service-request-row .edit {
  display: flex;
  width: 10vw;
  flex-direction: row;
  justify-content: space-evenly;
}

.table-row:nth-child(even) {
  background-color: #e9e9e9;
}

.search-bar {
  margin: 25px auto;
  width: 69vw;
}

.search-button {
  background-color: var(--orange);
  height: 3.33rem;
  width: 3.33rem;
  place-content: center;
  border-radius: 20px;
}

.search-input {
  height: 3.33rem;
  width: 55vw;
  border-radius: 20px;
  padding: auto 5px;
}
