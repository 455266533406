.SearchBarInput{
	position: relative;
	margin-top:2.02vw;
	margin-left:2.02vw;
	width: 55vw;
	height: 3.76vw;
	background: #F6F6F5;
	border-radius: 1.38vw;
	padding-left:1.33vw;
	z-index:1;
	
	font-family: SophiaPro;
	font-style: normal;
	font-weight: 600;
	font-size: 1.33vw;
	line-height: 1.5vw;
	align-items: center;
	color: #7E8080;
}

.SearchBarIcon{
	position: absolute;
	top:2.02vw;
	left:53.9vw;
	width: 3.76vw;
	height: 3.76vw;
	background:  #F3752B;
	border-radius: 1.38vw;
	z-index:2;
	padding:1.2vw;
}

.SearchResult{
	position: relative;
	width: 52vw;
	height: 3.76vw;
	margin-left:2.72vw;
	background: #E9E9E9;
	z-index:10;
	display:flex;
	flex-direction:row;
	padding:1.33vw;
	
	font-family: SophiaPro;
	font-style: normal;
	font-weight: normal;
	font-size: 1.33vw;
	line-height: 1.5vw;
	align-items: center;
	color: #7E8080;
}

.SearchResultItem{
	font-family: SophiaPro;
	font-style: normal;
	font-weight: normal;
	font-size: 1.33vw;
	line-height: 1.5vw;
	align-items: center;
	color: #7E8080;
}

.BlockButton{
	position: relative;
	width: fit-content;
	height: 2.67vw;
	margin-right:2vw;
	margin-left:2vw;
	margin-top:1.5vw;
	padding-bottom:0.96vw;
	padding-top:0.96vw;
	padding-left:0.59vw;
	padding-right:0.59vw;	
	background: red;
    border-radius: 0.83vw;
	font-family: SophiaPro;
	font-style: normal;
	font-weight: normal;
	font-size: 1.11vw;
	line-height: 1.3vw;
	display: flex;
	align-items: center;
	color: #FFFFFF;
	cursor:pointer;
}

.BlockButtonDisabled{
	position: relative;
	width: fit-content;
	height: 2.67vw;
	margin-right:2vw;
	margin-left:2vw;
	margin-top:1.5vw;
	padding-bottom:0.96vw;
	padding-top:0.96vw;
	padding-left:0.59vw;
	padding-right:0.59vw;	
	background: #7E8080;
    border-radius: 0.83vw;
	font-family: SophiaPro;
	font-style: normal;
	font-weight: normal;
	font-size: 1.11vw;
	line-height: 1.3vw;
	display: flex;
	align-items: center;
	color: #FFFFFF;
	cursor:pointer;
	display:none;
	height:0;
}