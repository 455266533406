.single-form-response {
  width: 70vw;
  background-color: #f6f6f6;
  margin: auto;
  border-radius: 20px;
  padding: 10px;
  color: #646464;
}

.response-detail {
  width: 68vw;
  background-color: #e9e9e9;
  margin: 10px auto;
  border-radius: 10px;
  height: 30vh;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
