.gallery-header {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  margin-right: 2vw;
  margin-left: 2vw;
  grid-column-gap: 1vh;
  margin-top: 75px;
}

.gallery-main-header {
  grid-column: 6/7;
  font-weight: 500;
  font-size: 3em;
  margin: 0;
}

.gallery-header-img {
  grid-column: 6;
  margin-bottom: -24px;
  margin-left: -34px;
}

.image-main {
  max-width: 38vw;
  max-height: 32vw;
  border-radius: 14px;
}

.gallery-carousel {
  align-items: center;
  display: flex;
  margin: 2rem auto;
  overflow: hidden;
  position: relative;
  max-width: 38vw;
}

.carousel__images {
  display: flex;
  transform: translateX(0);
  transition: transform 0.25s;
}

.carousel__button {
  background: teal;
  border: 0;
  border-radius: 50%;
  color: white;
  cursor: pointer;
  font-weight: bold;
  height: 1.6rem;
  opacity: 0.25;
  position: absolute;
  transition: opacity 0.25s;
  width: 1.6rem;
  z-index: 1;
}

.previous {
  left: 5px;
}

.next {
  right: 5px;
}

.carousel__button:hover {
  opacity: 0.5;
}

@media screen and (min-width: 700px) and (max-width: 1024px) {
  .gallery-header {
    width: 100%;
    height: auto;
    display: block;
    margin-right: 2vw;
    margin-left: 2vw;
    margin-top: 75px;
    text-align: center;
  }

  .gallery-main-header {
    font-weight: 500;
    font-size: 2.5em;
    margin: 0;
  }

  .gallery-header-img {
    margin-left: -120px;
    margin-bottom: -5px;
  }

  .gallery-carousel {
    align-items: center;
    display: flex;
    margin: 2rem auto;
    overflow: hidden;
    position: relative;
    max-width: 60vw;
  }
  .image-main {
    max-width: 60vw;
    max-height: 45vw;
    border-radius: 14px;
  }
}

@media screen and (max-width: 699px) {
  .gallery-header {
    width: 100%;
    height: auto;
    display: block;
    margin-right: 2vw;
    margin-left: 2vw;
    margin-top: 75px;
    text-align: center;
  }

  .gallery-main-header {
    font-weight: 500;
    font-size: 3rem;
    margin: 0;
  }

  .gallery-header-img {
    display: none;
  }

  .gallery-carousel {
    align-items: center;
    display: flex;
    margin: 2rem auto;
    overflow: hidden;
    position: relative;
    max-width: 80vw;
  }
  .image-main {
    max-width: 80vw;
    max-height: 60vw;
    border-radius: 14px;
  }
}
