/***************************************************************/
/*                          Fonts                              */
/***************************************************************/

@font-face {
  font-family: SophiaPro;
  src: url("./public/fonts/Sofia\ Pro\ Regular\ Az.otf") format("opentype");
}
@font-face {
  font-family: SophiaPro;
  font-style: italic;
  src: url("./public/fonts/Sofia\ Pro\ Regular\ Italic\ Az.otf");
}

/* Black */
@font-face {
  font-family: SophiaPro;
  font-weight: 800;
  src: url("./public/fonts/Sofia\ Pro\ Black\ Az.otf") format("opentype");
}

@font-face {
  font-family: SophiaPro;
  font-weight: 800;
  font-style: italic;
  src: url("./public/fonts/Sofia\ Pro\ Black\ Italic\ Az.otf")
    format("opentype");
}

/* Bold */
@font-face {
  font-family: SophiaPro;
  font-weight: bold;
  src: url("./public/fonts/Sofia\ Pro\ Bold\ Az.otf") format("opentype");
}

@font-face {
  font-family: SophiaPro;
  font-weight: bold;
  font-style: italic;
  src: url("./public/fonts/Sofia\ Pro\ Bold\ Italic\ Az.otf") format("opentype");
}

/* Semi Bold */
@font-face {
  font-family: SophiaPro;
  font-weight: 600;
  src: url("./public/fonts/Sofia\ Pro\ Semi\ Bold\ Az.otf");
}

@font-face {
  font-family: SophiaPro;
  font-weight: 600;
  font-style: italic;
  src: url("./public/fonts/Sofia\ Pro\ Semi\ Bold\ Italic\ Az.otf");
}

/* Medium */
@font-face {
  font-family: SophiaPro;
  font-weight: 500;
  src: url("./public/fonts/Sofia\ Pro\ Medium\ Az.otf");
}

@font-face {
  font-family: SophiaPro;
  font-weight: 500;
  font-style: italic;
  src: url("./public/fonts/Sofia\ Pro\ Medium\ Italic\ Az.otf");
}

/* Light */
@font-face {
  font-family: SophiaPro;
  font-weight: 300;
  src: url("./public/fonts/Sofia\ Pro\ Light\ Az.otf");
}

@font-face {
  font-family: SophiaPro;
  font-weight: 300;
  font-style: italic;
  src: url("./public/fonts/Sofia\ Pro\ Light\ Italic\ Az.otf");
}

/* Extra Light */
@font-face {
  font-family: SophiaPro;
  font-weight: 200;
  src: url("./public/fonts/Sofia\ Pro\ ExtraLight\ Az.otf");
}

@font-face {
  font-family: SophiaPro;
  font-style: italic;
  font-weight: 200;
  src: url("./public/fonts/Sofia\ Pro\ ExtraLight\ Italic\ Az.otf");
}

/* Ultra Light */
@font-face {
  font-family: SophiaPro;
  font-weight: 100;
  src: url("./public/fonts/Sofia\ Pro\ UltraLight\ Az.otf");
}

@font-face {
  font-family: SophiaPro;
  font-weight: 100;
  font-style: italic;
  src: url("./public/fonts/Sofia\ Pro\ UltraLight\ Italic\ Az.otf");
}

@font-face {
  font-family: Nasalization;
  font-weight: 400;
  src: url("./public/fonts/nasalization-rg.otf");
}

/***************************************************************/
/*                     Design Guideline                        */
/***************************************************************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: SophiaPro;
}

:root {
  font-family: SophiaPro;
  --blue: #00b4d8;
  --orange: #f3752b;
  --gray: #dddddd;
  --white: #ffffff;
  --black: #000000;
  --grayLight: #979797;
  --grayDark: #434343;
  font-size: 18px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: scroll;
}

.grid-main {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  margin-right: 1vw;
  margin-left: 1vw;
  grid-column-gap: 1vw;
}

@media screen and (min-width: 700px) and (max-width: 1024px) {
  /* Small Screen View */
  * {
    font-size: 12px;
    font-weight: 400;
  }
}

@media screen and (max-width: 699px) {
  /* Mobile View */
  * {
    font-size: 8px;
    font-weight: 400;
  }
}
