.footer {
  background-color: var(--black);
  color: var(--grayLight);
  font-size: 14px;
  font-weight: 600;
  /* position: absolute;
  bottom: 1vh; */
  width: 100vw;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  padding-left: 2vw;
  padding-right: 2vw;
  grid-column-gap: 1vw;
  margin: 0;
  padding-top: 10px;
}

.support {
  grid-column: 3;
}

.track {
  grid-column: 4;
}

.connect-with-us {
  grid-column: 9 / 10;
  width: 100%;
  text-align: right;
}

.footer-logo {
  grid-column: 3;
  margin-top: 50px;
}

.footer-content-1 {
  grid-column: 4 / span 2;
  width: 100%;
  margin-top: 50px;
}

.footer-content-2 {
  grid-column: 9;
  margin-top: 50px;
  text-align: right;
}

.footer a {
  color: var(--grayLight);
}

hr {
  margin-top: 15px;
  color: #343434;
}

.footer-overall {
  background-color: var(--black);
  color: var(--grayLight);
  font-size: 14px;
  font-weight: 600;
  /* position: absolute;
  bottom: 1vh; */
  width: 100vw;
  margin: 0;
  margin-top: 50px;
}

.footer-down {
  font-size: 13px;
  font-weight: 500;
  color: var(--grayDark);
  width: 100vw;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  padding-left: 2vw;
  padding-right: 2vw;
  grid-column-gap: 1vw;
  margin: 0;
}

.privacy-policy {
  grid-column: 3;
}
.tnc {
  grid-column: 4 / span 2;
}
.copyright {
  grid-column: 8 / 10;
  text-align: right;
}

@media screen and (max-width: 1024px) and (min-width: 700px) {
  .support {
    grid-column: 2;
  }

  .track {
    grid-column: 3;
  }

  .footer-logo {
    grid-column: 2;
    margin-top: 30px;
  }

  .connect-with-us {
    grid-column: 10 / 11;
    width: 100%;
    text-align: right;
  }

  .footer-content-2 {
    grid-column: 10;
    margin-top: 30px;
    text-align: right;
  }
}

@media screen and (max-width: 699px) {
  .footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .connect-with-us {
    width: auto;
  }

  .footer img {
    display: none;
  }

  .footer a {
    padding: 12px;
    font-size: 12px;
  }

  .footer p {
    font-size: 12px;
    padding: 12px;
  }
}
