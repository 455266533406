.partner-content-section {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 1vw;
  margin-top: 100px;
}

.partner-content-section-header {
  grid-column: 5 / span 4;
  font-size: 2em;
}

.partner-content-section-header p {
  margin: -30px 0 0 0;
  text-align: center;
}

.partner-content-section-content {
  grid-column: 3 / span 8;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  font-weight: 300;
  line-height: 153%;
  margin: 50px auto 50px auto;
}

ul.left-align-content {
  text-align: left;
  list-style: disc;
}

.left-align-content li {
  list-style: disc;
  font-weight: 300;
  line-height: 153%;
}
/* .what-we-offer-header > img:nth-child(1) {
  margin-left: 120px;
}

.bespoke-requests-header > img:nth-child(1) {
  margin-left: 12px;
} */

@media screen and (min-width: 700px) and (max-width: 1024px) {
  .partner-content-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
    justify-content: space-evenly;
  }

  .partner-content-section-header {
    grid-column: 5 / span 3;
    font-size: 2em;
  }

  .partner-content-section-header p {
    font-size: 2rem;
    text-align: center;
    margin-top: -30px;
  }

  .partner-content-section-header img {
    margin-left: -35px;
  }

  .partner-content-section-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 50vw;
    margin-top: 30px;
  }

  ul.left-align-content {
    text-align: left;
    list-style: disc;
  }

  .left-align-content li {
    list-style: disc;
  }

  .what-we-offer-header > img:nth-child(1) {
    margin-left: -35px;
  }
  .bespoke-requests-header > img:nth-child(1) {
    margin-left: -35px;
  }
}

@media screen and (max-width: 699px) {
  .partner-content-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 100px;
  }

  .partner-content-section-header {
    font-size: 2em;
  }

  .partner-content-section-header p {
    font-size: 2rem;
    text-align: center;
    margin-top: -30px;
  }

  .partner-content-section-header img {
    display: none;
  }

  .partner-content-section-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 70vw;
    margin-top: 30px;
    font-size: 1.8em;
  }

  ul.left-align-content {
    text-align: left;
    list-style: disc;
  }

  .left-align-content li {
    list-style: disc;
    font-size: 1.8em;
  }
}
