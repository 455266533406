.ControlPanel{
	position: relative;
    width: 25vw;
    height: 100vh;
    left:0;
	top:0;
    background: #00B4D8;
	padding-top:6.09vw;
	overflow-x:auto;
	overflow-y:auto;
}

.LogoAndTitle{
	display:flex;
	height:4.16vw;
	margin-left:5.97vw;
	margin-bottom:4vw;
	align-items:row;
	background: #00B4D8;
}

.Logo{
	height:4.16vw;
	width:4.86vw;
	/* background: url("https://raw.githubusercontent.com/Nikitha2309/Private/main/Group%2033980.png?token=APXZ46IOUCM75DLH5BEPYPDBQEHJK")
    no-repeat; */
	background-repeat:no-repeat;
  background-size: contain;
}

.Title{
	margin-top:auto;
	margin-bottom:auto;
    height: 1.9vw;
	font-family: Nasalization;
	font-style: normal;
	font-weight: normal;
	font-size: 1.6vw;
	line-height: 1.9vw;
	color: #FFFFFF;
}

.subMenu{
	background:#00B4D8;
	margin-left: 2vw;
	width: 23vw;
}

.arrow{
	position: absolute;
	right:10%;
}